body { 
    background: #f9f3ea;
}

.checkbox-icon {
    position: absolute;
    top: 5px;
    left: 5px;
}

.checkbox {
    position: relative;
}